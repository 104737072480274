@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'SKODANext';
  font-weight: normal;
  src: url('../fonts/SkodaNextRegular.woff2');
}

@font-face {
  font-family: 'SKODANext';
  font-weight: 300;
  src: url('../fonts/SkodaNextLight.woff2');
}

@font-face {
  font-family: 'SKODANext';
  font-weight: bold;
  src: url('../fonts/SkodaNextBold.woff2');
}

body {
  @apply antialiased text-text;
  font-size: 16px;
}

.button {
  @apply appearance-none;
  @apply font-bold;
  @apply inline-flex;
  @apply py-3 px-12 rounded-button;
  @apply transition-colors duration-300;
}

.button-primary {
  @apply bg-primary hover:bg-primary-hover text-text hover:text-primary-bg;
  @apply border border-primary hover:border-primary-hover;
  @apply text-xl;
}

.button-secondary {
  @apply bg-transparent hover:bg-gray-bg text-text;
  @apply border border-text;
  @apply text-sm;
}

[type="text"],
[type="password"],
[type="email"],
select {
  @apply transition-colors duration-300;
  @apply ring-0 focus:ring-0;
  @apply bg-gray-bg border-t-0 border-l-0 border-r-0 border-b-2 border-black;
  @apply focus:border-t-0 focus:border-l-0 focus:border-r-0;
  @apply focus:border-black focus:bg-gray-50;
}

.site-header {
  @apply absolute w-full top-0 left-0 block bg-white shadow-sm z-20;
  @apply h-[50px] lg:h-[100px];
}

.site-header .container {
  @apply flex justify-between items-center h-full;
  @apply px-4 md:px-8;
}

.site-header__logo {
  @apply w-28;
}

.site-footer {
  @apply bg-gray-bg text-sm;
}

.site-footer .container {
  @apply px-4 md:px-0 py-6 border-t flex flex-col md:flex-row md:justify-between md:items-center;
}

.site-footer__navigation {
  @apply list-none flex flex-col lg:flex-row mb-4 md:mb-0;
}

@screen lg {
  .site-footer__navigation li:not(:last-child):after {
    content: '|';
    @apply px-2;
  }
}

.site-footer__navigation li a {
  @apply hover:underline;
}

.hero {
  @apply w-full h-[75vh] lg:h-[85vh] relative;
  @apply flex flex-col justify-end items-start;
  @apply bg-no-repeat bg-cover;
  background-image: url("/img/hero.jpg");
  background-position: -220px 0;
}

.hero .container {
  @apply p-4 md:p-8 lg:pb-12;
}

@screen md {
  .hero {
    background-position: center bottom;
  }
}

.hero__title h1 {
  @apply font-bold text-primary;
  @apply text-2xl md:text-5xl lg:text-6xl xl:text-8xl;
}

.hero__title h2 {
  @apply text-white font-light md:mt-2;
  @apply text-lg sm:text-xl md:text-4xl lg:text-5xl xl:text-6xl;
}

.cars {
  @apply p-4 md:p-8 lg:p-12;
  @apply relative;
}

.cars .container {
  @apply text-center;
}

.cars__title {
  @apply text-2xl sm:text-3xl md:text-5xl lg:text-6xl font-bold;
}

.cars__item {
  @apply flex flex-col justify-center items-center gap-3;
  @apply w-full lg:max-w-screen-lg;
}

.cars__item > img {
  @apply w-full md:max-w-screen-sm lg:max-w-screen-md;
}

.cars__item > div p {
  @apply mb-4;
}

.cars__item__image {
  @apply h-auto sm:h-[270px] md:h-[320px] lg:h-[480px];
}

.cars__item__image img {
  @apply h-auto w-auto;
}

.cars__item h2 {
  @apply text-3xl font-bold uppercase mb-2;
}

.carousel__arrow {
  @apply absolute top-40 md:top-56 lg:top-80;
  @apply w-[25px] h-[55px] lg:w-[32px] lg:h-[70px];
}

.carousel__arrow.is-prev {
  @apply left-4 md:left-8 lg:left-52;
}

.carousel__arrow.is-next {
  @apply right-4 md:right-8 lg:right-52;
}

.locations {
  @apply bg-primary-bg text-primary text-center;
  @apply p-4 md:p-8 lg:p-12;
}

.locations__title {
  @apply text-2xl sm:text-3xl md:text-5xl lg:text-7xl font-bold;
  @apply mb-8 md:mb-8;
}

.locations__tabs {
  @apply flex flex-col gap-2 md:flex-row md:justify-center md:gap-4;
  @apply mb-4 md:mb-8;
}

.locations__tabs button {
  @apply lowercase font-bold text-sm lg:text-base;
  @apply opacity-50;
}

.locations__tabs button[aria-selected="true"] {
  @apply opacity-100 border-b border-primary;
}

.locations__grid {
  @apply flex flex-wrap justify-center gap-6 mb-8;
}

.locations__item {
  @apply relative;
  @apply flex flex-col gap-3 justify-center items-center;
  @apply bg-primary-bg-light p-4 rounded-xl text-white text-sm;
  @apply cursor-pointer;
  @apply transition-opacity duration-1000;
  @apply w-full sm:w-[40%] md:w-1/3 lg:w-1/4 xl:w-1/5 2xl:w-1/6;
}

.locations__item:after {
  content: "";
  display: none;
  height: 20px;
  width: 20px;
  background-color: inherit;
  border: inherit;
  position: absolute;
  bottom: -10px;
  left: calc(50% - 10px);
  clip-path: polygon(0% 0%, 100% 100%, 0% 100%);
  transform: rotate(-45deg);
  border-radius: 0 0 0 4px;
}

.locations__item__pin {
  @apply w-[36px] h-[58px] block my-2;
  @apply bg-no-repeat bg-cover;
  background-image: url(/img/pin.svg);
}

.locations__item.is-selected {
  @apply shadow-xl scale-125;
  @apply transition-all duration-500;
  @apply z-30;
}

.locations__item.is-selected:after {
  display: block;
}

.is-dimmed .locations__item:not(.is-selected) {
  @apply opacity-40;
}

.is-slightly-dimmed .locations__item:not(.is-selected) {
  @apply opacity-70;
}

.form {
  @apply min-h-[1000px] py-8 bg-gray-bg relative;
}

.form:before {
  content: "";
  @apply w-full h-[90%] absolute top-[2.5%] left-0 block;
  @apply bg-white drop-shadow-3xl;
  clip-path: polygon(0 10%, 100% 0, 100% 90%, 20% 100%, 0 75%);
}

.form .container {
  @apply h-full relative z-10;
}

.form__wrapper {
  @apply mx-auto max-w-full sm:max-w-screen-xs md:max-w-screen-sm lg:max-w-screen-md;
  @apply mt-20;
}

.form__row {
  @apply flex flex-col mb-4 max-w-[350px];
}

.form__row label {
  @apply font-bold uppercase mb-1;
}
